/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onInitialClientRender = () => {
  require('typeface-nunito-sans');
  window.metrical = {
    app: '04X3GdIbw',
  };
  const script = document.createElement('script');
  script.src = 'https://api.metrical.xyz/script.js';
  document.head.appendChild(script);
};
